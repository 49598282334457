import React from 'react';
import { useTheme } from '@mui/material/styles';
import { COMPANY_NAME } from '../../constants';

export interface SignantHealthLogoProps {
  width?: number;
}

export const SignantHealthLogo = ({ width }: SignantHealthLogoProps) => {
  const theme = useTheme();
  const lightMode = theme.palette.mode === 'light';

  const primaryTextColor = lightMode ? '#3d3935' : 'white';
  const secondaryTextColor = lightMode ? '#707272' : 'white';

  return (
    <svg
      aria-labelledby="SignantHealthLogoTitle"
      id="SignantHealthLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="100.809px"
      height="15.75px"
      viewBox="0 0 100.809 15.75"
      data-testid="SignantHealthLogo"
      style={{
        width: `${width}px`,
        height: 'auto',
      }}
    >
      <title id="SignantHealthLogoTitle">{COMPANY_NAME}</title>
      <path
        id="Path_92"
        fill={primaryTextColor}
        d="M22.763,10.609c0.053,0.764-0.195,1.517-0.691,2.1c-0.532,0.516-1.26,0.78-2,0.73 c-0.756,0.021-1.505-0.149-2.176-0.5v-1.801c0.589,0.428,1.289,0.676,2.017,0.715c0.291,0.022,0.579-0.082,0.787-0.289 c0.188-0.217,0.283-0.496,0.271-0.782c0-0.325-0.111-0.642-0.316-0.894c-0.299-0.352-0.646-0.654-1.035-0.9 c-0.355-0.232-0.678-0.514-0.954-0.834c-0.219-0.267-0.389-0.569-0.5-0.895c-0.126-0.37-0.188-0.759-0.181-1.15 c-0.039-0.731,0.212-1.448,0.7-1.994c0.492-0.503,1.176-0.771,1.878-0.736c0.766,0.004,1.515,0.222,2.161,0.629l-0.6,1.46 c-0.219-0.126-0.442-0.237-0.675-0.334c-0.238-0.099-0.495-0.148-0.755-0.146c-0.259-0.015-0.511,0.093-0.679,0.292 c-0.169,0.229-0.253,0.509-0.238,0.793c-0.005,0.312,0.096,0.616,0.283,0.864c0.289,0.329,0.626,0.611,1,0.837 c0.539,0.331,0.995,0.779,1.335,1.311C22.65,9.553,22.777,10.078,22.763,10.609z"
      />
      <path id="Path_93" fill={primaryTextColor} d="M24.297,13.307V3.519h1.714v9.788H24.297z" />
      <path
        id="Path_94"
        fill={primaryTextColor}
        d="M30.761,7.876h2.99v5.022c-0.854,0.36-1.772,0.545-2.7,0.541 c-1.042,0.049-2.035-0.441-2.629-1.299c-0.685-1.125-1.005-2.433-0.921-3.744c-0.079-1.313,0.291-2.615,1.05-3.69 c0.711-0.892,1.808-1.388,2.947-1.332c0.784,0,1.556,0.207,2.233,0.6l-0.573,1.487c-0.467-0.295-1.003-0.462-1.555-0.483 c-0.674-0.01-1.302,0.345-1.642,0.927c-0.44,0.767-0.649,1.645-0.602,2.526c-0.044,0.863,0.106,1.728,0.438,2.524 c0.206,0.524,0.712,0.872,1.276,0.876c0.303-0.001,0.604-0.039,0.898-0.113V9.482h-1.216V7.876H30.761z"
      />
      <path
        id="Path_95"
        fill={primaryTextColor}
        d="M42.376,13.307h-2.225l-3.023-7.091h-0.086c0.112,0.573,0.17,1.156,0.167,1.74v5.351h-1.688 V3.519h2.24l3,7.015h0.075c-0.092-0.554-0.135-1.114-0.128-1.675v-5.34h1.675L42.376,13.307z"
      />
      <path
        id="Path_96"
        fill={primaryTextColor}
        d="M49.01,13.307l-0.563-2.472h-2.551l-0.563,2.472h-1.822l2.423-9.827h2.471l2.431,9.827H49.01z M48.11,9.223l-0.521-2.277c-0.03-0.137-0.102-0.486-0.212-1.04c-0.112-0.554-0.176-0.927-0.2-1.115 c-0.057,0.384-0.131,0.808-0.221,1.27s-0.322,1.517-0.7,3.162H48.11z"
      />
      <path
        id="Path_97"
        fill={primaryTextColor}
        d="M58.83,13.307h-2.227L53.58,6.216h-0.086c0.112,0.573,0.17,1.156,0.167,1.74v5.351h-1.688	V3.519h2.235l3,7.015h0.075c-0.093-0.554-0.136-1.114-0.128-1.675v-5.34h1.675L58.83,13.307z"
      />
      <path
        id="Path_98"
        fill={primaryTextColor}
        d="M63.836,13.307h-1.815V5.119h-1.875v-1.6h5.564v1.6h-1.874V13.307z"
      />
      <path
        id="Path_99"
        fill={secondaryTextColor}
        d="M72.881,13.307h-0.656V8.493H68.81v4.813h-0.656V3.519h0.656v4.35h3.415v-4.35h0.656V13.307z"
      />
      <path
        id="Path_100"
        fill={secondaryTextColor}
        d="M78.57,13.307h-3.546V3.519h3.546v0.629h-2.891v3.721h2.735v0.629h-2.73v4.184h2.893	L78.57,13.307z"
      />
      <path
        id="Path_101"
        fill={secondaryTextColor}
        d="M84.822,13.307L83.958,9.76h-2.375l-0.851,3.547h-0.682l2.357-9.788h0.677l2.411,9.788H84.822 z M83.806,9.096l-0.864-3.647c-0.089-0.411-0.156-0.8-0.209-1.165c-0.039,0.392-0.102,0.78-0.188,1.165l-0.837,3.647H83.806z"
      />
      <path
        id="Path_102"
        fill={secondaryTextColor}
        d="M86.837,13.307V3.519h0.656v9.158h2.757v0.63H86.837z"
      />
      <path
        id="Path_103"
        fill={secondaryTextColor}
        d="M92.95,13.307h-0.656V4.148h-1.949V3.519h4.551v0.629h-1.949v9.159H92.95z"
      />
      <path
        id="Path_104"
        fill={secondaryTextColor}
        d="M100.812,13.307h-0.656V8.493h-3.413v4.813h-0.656V3.519h0.656v4.35h3.413v-4.35h0.656V13.307 z"
      />
      <g>
        <g>
          <defs>
            <circle id="SVGID_1_" cx="7.889" cy="7.888" r="7.888" />
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_1_" overflow="visible" />
          </clipPath>
          <g id="Group_18346" clipPath="url(#SVGID_2_)">
            <path
              id="Path_8844"
              fill="#99CFE7"
              d="M10.923,0.324C6.71-1.448,1.857,0.531,0.086,4.743C-1.686,8.957,0.294,13.81,4.506,15.58 c4.213,1.771,9.065-0.207,10.838-4.42c0,0,0,0,0-0.001C17.115,6.947,15.136,2.096,10.923,0.324z M6.065,3.984 c3.168,1.454,4.995,4.46,4.451,7.136C7.347,9.668,5.521,6.66,6.065,3.984z"
            />
            <path
              id="Path_8845"
              fill="#3CB4E5"
              d="M6.065,3.985c-0.089-0.04-0.181-0.083-0.272-0.12C2.096,2.31-1.901,3.434-3.138,6.373 c-1.237,2.939,0.759,6.584,4.454,8.136c3.697,1.556,7.693,0.432,8.931-2.508c0.12-0.285,0.21-0.579,0.272-0.884 C7.347,9.666,5.521,6.66,6.065,3.985z"
            />
            <path
              id="Path_8846"
              fill="#E4E5E6"
              d="M15.264,0.596c-3.697-1.555-7.693-0.431-8.93,2.507c-0.12,0.285-0.21,0.58-0.272,0.883 c3.168,1.454,4.996,4.46,4.452,7.136c0.089,0.041,0.181,0.084,0.272,0.119c3.697,1.557,7.693,0.431,8.931-2.509 C20.955,5.792,18.96,2.148,15.264,0.596z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

SignantHealthLogo.defaultProps = {
  width: 100.809,
};
