import React, { forwardRef, ReactNode } from 'react';
import { styled, IconButton, Drawer, Box } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import { usePageContext } from './PageContext';

const getUnitValue = (value) => {
  return Number(String(value).replace('px', ''));
};

interface PageSidebarWrapperProps {
  sidebarWidth?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
}

const Sidebar = styled(Drawer)({
  flexShrink: 0,
  overflowX: 'hidden',
  transition: '0.3s',
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box',
    position: 'relative',
    overflowX: 'hidden',
    zIndex: 0,
  },
});

const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

enum SidebarWidth {
  open = 350,
  closed = 57,
}

export const PageSidebarWrapper = forwardRef<HTMLDivElement, PageSidebarWrapperProps>(
  ({ className, sidebarWidth = SidebarWidth.open, children }, ref) => {
    const { open, openSidebar, closeSidebar } = usePageContext();
    return (
      <Sidebar
        className={classNames('page-sidebar-wrapper', className)}
        open={open}
        ref={ref}
        variant="permanent"
        PaperProps={{
          elevation: 2,
          className: 'page-sidebar-wrapper-paper',
        }}
        sx={{
          width: open ? sidebarWidth : Math.min(getUnitValue(sidebarWidth), SidebarWidth.closed),
        }}
      >
        <DrawerHeader>
          {open && (
            <IconButton onClick={closeSidebar} data-testid="sidebar-close-button">
              <ChevronLeftIcon />
            </IconButton>
          )}
          {!open && (
            <IconButton onClick={openSidebar} data-testid="sidebar-open-button">
              <ChevronRightIcon />
            </IconButton>
          )}
        </DrawerHeader>
        {children}
      </Sidebar>
    );
  }
);
