import React, { forwardRef, ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import { CssGridArea } from '../CssGridArea';

interface PageHeaderWrapperProps {
  className?: string;
  children?: ReactNode | ReactNode[];
  sx?: SxProps;
}

export const PageHeaderWrapper = forwardRef<HTMLDivElement, PageHeaderWrapperProps>(
  (props, ref) => {
    return (
      <CssGridArea
        ref={ref}
        area="header"
        {...props}
        className={`page-header-wrapper ${props.className ? props.className : ''}`}
      >
        {props.children}
      </CssGridArea>
    );
  }
);
