import React, { forwardRef, ReactNode } from 'react';
import { CssGrid } from '../CssGrid';

export interface PageProps {
  templateRows?: string;
  templateColumns?: string;
  templateAreas?: string;
  className?: string;
  children: ReactNode | ReactNode[];
}

const defaultProps = {
  templateRows: '64px 1fr 40px',
  templateAreas: `'header' 'content' 'footer'`,
};

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ templateRows, templateColumns, templateAreas, ...props }, ref) => {
    return (
      <CssGrid
        ref={ref}
        {...props}
        gridTemplateRows={templateRows ?? defaultProps.templateRows}
        gridTemplateAreas={templateAreas ?? defaultProps.templateAreas}
        gridTemplateColumns={templateColumns}
      >
        {props.children}
      </CssGrid>
    );
  }
);
