import { useEffect, useState } from 'react';
import { helixDark, helixLight } from '../../themes';
import { LOCALSTORAGE_NAMESPACE } from '../../constants';

const THEME_STORAGE_KEY = `${LOCALSTORAGE_NAMESPACE}:theme`;

enum ThemeName {
  Light = 'light',
  Dark = 'dark',
}

const initializeThemeChangerState = () => {
  let prefersDarkMode = false;
  let storageTheme;

  // first, check if theme has already been set in localstorage
  try {
    storageTheme = localStorage.getItem(THEME_STORAGE_KEY);
  } catch {
    // do nothing
  }

  // if localstorage theme setting exists, use this.
  // if not, check for user's system preference for dark mode
  if (storageTheme) {
    prefersDarkMode = storageTheme === ThemeName.Dark;
  } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    prefersDarkMode = true;
  }

  return prefersDarkMode;
};

export const useThemeSwitcher = () => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(initializeThemeChangerState);

  const toggleTheme = () => {
    setIsDarkMode((prevIsDarkMode) => !prevIsDarkMode);
  };

  // When theme is set, save the setting into localstorage for future page loads
  useEffect(() => {
    try {
      localStorage.setItem(THEME_STORAGE_KEY, isDarkMode ? ThemeName.Dark : ThemeName.Light);
    } catch {
      // do nothing
    }
  }, [isDarkMode]);

  return {
    theme: isDarkMode ? helixDark : helixLight,
    toggleTheme,
  };
};
