import React, { createContext, ReactNode, useContext, useState } from 'react';

interface PageContextState {
  open: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
}

export const PageContext = createContext<PageContextState>({
  open: true,
  openSidebar: () => {},
  closeSidebar: () => {},
});

export const usePageContext = () => useContext(PageContext);

export const usePageContextValue = (): PageContextState => {
  const [open, setOpen] = useState<boolean>(true);

  const openSidebar = (): void => {
    setOpen(true);
  };

  const closeSidebar = (): void => {
    setOpen(false);
  };

  return {
    open,
    openSidebar,
    closeSidebar,
  };
};

interface PageContextProviderProps {
  children: ReactNode | ReactNode[];
}

export const PageContextProvider = ({ children }: PageContextProviderProps): JSX.Element => {
  const value = usePageContextValue();
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
