import React, { forwardRef, ReactNode } from 'react';
import { CssGridArea } from '../CssGridArea';

interface PageFooterWrapperProps {
  className?: string;
  children?: ReactNode | ReactNode[];
}

export const PageFooterWrapper = forwardRef<HTMLDivElement, PageFooterWrapperProps>(
  (props, ref) => {
    return (
      <CssGridArea
        ref={ref}
        area="footer"
        {...props}
        className={`page-footer-wrapper ${props.className ? props.className : ''}`}
      >
        {props.children}
      </CssGridArea>
    );
  }
);
