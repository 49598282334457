import * as React from 'react';
import { History } from 'history';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Link, Paper, styled, Typography, Stack } from '@mui/material';

/** A link that will route to a specific location */
interface BreadcrumbLink {
  /** Will be displayed for the user to see. */
  display: string;
  /** The route the user will be redirected to. */
  route?: string;
}

export interface BreadcrumbProps {
  /* Items to be displayed in breadcrumb */
  breadcrumbs: BreadcrumbLink[];
  /* Required if consumer uses react-router-dom to push on the consumer's history when navigating link refs */

  history?: History;
}

const BreadcrumbContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  paddingLeft: theme.spacing(2),
  minHeight: 48,
}));

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { breadcrumbs, history } = props;

  const Breadcrumbs: React.ReactNode[] = [];
  breadcrumbs.forEach((bc: BreadcrumbLink, i: number) => {
    const id = `BreadCrumb_${i}`;
    if (i > 0) {
      Breadcrumbs.push(
        <ChevronRight
          key={`svg${id}`}
          fontSize="small"
          color="primary"
          id="chevron"
          data-testid="chevron"
        />
      );
    }
    if (bc.route) {
      Breadcrumbs.push(
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          id={`a${id}`}
          key={`a${id}`}
          title={bc.display}
          href={history ? undefined : bc.route}
          onClick={history && bc.route ? () => history.push(bc.route || '/') : undefined}
          underline="none"
          sx={{ cursor: 'pointer' }}
        >
          {bc.display}
        </Link>
      );
    } else {
      Breadcrumbs.push(
        <Typography id={`lbl${id}`} key={`lbl${id}`} title={bc.display}>
          {bc.display}
        </Typography>
      );
    }
  });
  return (
    <Paper elevation={2} square>
      <BreadcrumbContainer id="breadCrumbContainer" direction="row" spacing={1}>
        {Breadcrumbs}
      </BreadcrumbContainer>
    </Paper>
  );
};
