import React, { createContext, useMemo, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { GlobalLogoutManager } from '@signanthealth/frontend-utilities';

export interface SecurityWrapperProps {
  children: JSX.Element;
  oktaAuth: OktaAuth;
  applicationPath: string;
  enableGlobalLogout?: boolean;
}

interface SecurityWrapperContextValue {
  triggerGlobalLogout: () => void;
  handleStayLoggedIn: () => void;
  showInactivityWarning: boolean;
  logoutTimestamp: number | null;
}

export const SecurityWrapperContext = createContext<SecurityWrapperContextValue>({
  triggerGlobalLogout: () => {},
  handleStayLoggedIn: () => {},
  showInactivityWarning: false,
  logoutTimestamp: null,
});

export const SecurityWrapper = (props: SecurityWrapperProps) => {
  const { oktaAuth, children, applicationPath, enableGlobalLogout } = props;
  const history = useHistory();

  const [showInactivityWarning, setShowInactivityWarning] = useState(false);
  const [logoutTimestamp, setLogoutTimestamp] = useState<number | null>(null);

  const isLoginFlow = oktaAuth.isLoginRedirect();

  const handleGlobalLogout = () => {
    GlobalLogoutManager.stopPolling();
    GlobalLogoutManager.triggerGlobalLogout();
    oktaAuth.signOut();
  };

  const handleStayLoggedIn = () => {
    GlobalLogoutManager.dismissTimeoutWarning();
    setShowInactivityWarning(false);
    setLogoutTimestamp(null);
  };

  const globalLogoutHandler = (authState) => {
    if (!authState.isAuthenticated) {
      return;
    }

    if (!GlobalLogoutManager.isSetup()) {
      GlobalLogoutManager.setup({
        onGlobalLogout: () => {
          handleGlobalLogout();
        },
        onUserChange: async () => {
          GlobalLogoutManager.stopPolling();
          try {
            const data = await oktaAuth.token.getWithoutPrompt();
            oktaAuth.tokenManager.setTokens({
              accessToken: data.tokens.accessToken,
              idToken: data.tokens.idToken,
            });
            GlobalLogoutManager.startPolling();
          } catch (e) {
            oktaAuth.token.getWithRedirect();
          }
        },
        onTimeoutWarning: (logoutTimestampParam: number) => {
          setLogoutTimestamp(logoutTimestampParam);
          setShowInactivityWarning(true);
          // use warningTimestamp to determine logout countdown
        },
        onExternalActivityDetected: () => {
          setLogoutTimestamp(null);
          setShowInactivityWarning(false);
        },
        userEmail: authState?.idToken?.claims.email || '',
      });
    }

    if (isLoginFlow) {
      GlobalLogoutManager.createCookie();
    }

    GlobalLogoutManager.startPolling();

    oktaAuth.authStateManager.unsubscribe(globalLogoutHandler);
  };

  useEffect(() => {
    if (enableGlobalLogout) {
      oktaAuth.authStateManager.subscribe(globalLogoutHandler);
    }
  }, []);

  const contextValue = useMemo(
    () => ({
      triggerGlobalLogout: () => {
        if (enableGlobalLogout) {
          handleGlobalLogout();
        }
      },
      handleStayLoggedIn,
      showInactivityWarning,
      logoutTimestamp,
    }),
    [showInactivityWarning, logoutTimestamp]
  );

  /* istanbul ignore next */
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    const path = originalUri ? originalUri.replace(applicationPath, '') : '/';
    history.replace(toRelativeUrl(path, window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecurityWrapperContext.Provider value={contextValue}>
        {children}
      </SecurityWrapperContext.Provider>
    </Security>
  );
};

export default SecurityWrapper;
