import React, { forwardRef, ReactNode } from 'react';
import { styled, Box } from '@mui/material';

export interface CssGridAreaProps {
  area: string;
  className?: string;
  children?: ReactNode | ReactNode[];
}

const StyledGridArea = styled(Box)<CssGridAreaProps>(({ area }) => ({
  gridArea: area,
}));

export const CssGridArea = forwardRef<HTMLDivElement, CssGridAreaProps>((props, ref) => {
  return (
    <StyledGridArea
      ref={ref}
      {...props}
      className={`grid-css-area ${props.className ? props.className : ''}`}
    >
      {props.children}
    </StyledGridArea>
  );
});
