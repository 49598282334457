import * as React from 'react';
import { styled, Typography, Box, CircularProgress } from '@mui/material';

const ProgressContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2000,
});

const ProgressBox = styled(Box)(({ theme }) => ({
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  padding: '15px 25px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'var(--dark-theme-ashe-black-primary)'
      : 'var(--light-theme-ashe-white-light)',
  borderRadius: '6px',
  zIndex: 2000,
}));

export interface ProgressIndicatorProps {
  /**  id for overlay div */
  containerId?: string;
  /**  id for spinner */
  spinnerId?: string;
  /**  flag to determine if spinner is displayed */
  inProgress: boolean;
  /**  size of spinner (default is 40) */
  spinnerSize?: number;
  /**  message to display under the spinner */
  message?: string;
}

/**
 * Reusable progress spinner component.
 * @type {object}
 * @description Displays a overlay div with a spinner when the inProgress flag is set to true
 * @param props
 */
export const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const {
    containerId = 'progressContainer',
    spinnerId = 'progressSpinner',
    inProgress = true,
    spinnerSize = 40,
    message,
  } = props;

  if (!inProgress) return null;

  return (
    <ProgressContainer id={containerId}>
      <ProgressBox>
        <CircularProgress id={spinnerId} size={spinnerSize} data-testid="progressSpinnerIcon" />
        {message && <Typography data-testid="progressMessage">{message}</Typography>}
      </ProgressBox>
    </ProgressContainer>
  );
};
