import React from 'react';
import { SxProps } from '@mui/material/styles';
import MUIDialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Button, ButtonProps as MuiButtomProps } from '@mui/material';

interface ButtonProps extends MuiButtomProps {
  'data-testid'?: string;
}

export interface DialogProps {
  /** Controls the open state of the Dialog */
  open: boolean;
  /** Title of the dialog */
  title: React.ReactNode;
  /** Content body for the dialog. Can be any HTML/React component(s) */
  content: React.ReactNode;
  /** Props for action row Buttons */
  actionButtonProps: ButtonProps[];
  /** Centers the elements within the dialog */
  centered?: boolean;
  /** Controls display of <hr /> dividers above and below dialog content */
  showDividers?: boolean;
  /** Function to run when dialog is closed. Close button will not render if this prop is not provided */
  handleClose?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    reason?: 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'
  ) => void;
  dialogTitleSx?: SxProps;
  dialogPaperSx?: SxProps;
  dialogContentSx?: SxProps;
  dialogActionsSx?: SxProps;
}

export const Dialog = (props: DialogProps) => {
  const {
    open,
    title,
    content,
    actionButtonProps,
    centered,
    showDividers,
    handleClose,
    dialogPaperSx,
    dialogTitleSx,
    dialogContentSx,
    dialogActionsSx,
  } = props;

  return (
    <MUIDialog
      onClose={handleClose}
      aria-labelledby="dialog-title"
      open={open}
      PaperProps={{
        sx: {
          padding: 2,
          ...(centered && { alignItems: 'center' }),
          ...dialogPaperSx,
        },
      }}
    >
      <DialogTitle id="dialog-title" sx={dialogTitleSx}>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            data-testid="dialog-close-button"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      {showDividers && <Divider data-testid="dialog-title-divider" />}
      <DialogContent data-testid="dialog-content" sx={dialogContentSx}>
        {content}
      </DialogContent>
      {showDividers && <Divider data-testid="dialog-action-divider" />}
      <DialogActions sx={dialogActionsSx}>
        {actionButtonProps.map((buttonProps, index) => (
          <Button {...buttonProps} key={buttonProps.key ?? index} />
        ))}
      </DialogActions>
    </MUIDialog>
  );
};

Dialog.defaultProps = {
  centered: false,
  showDividers: false,
};
