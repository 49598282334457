import React, { forwardRef, ReactNode } from 'react';
import { styled } from '@mui/material';
import { CssGridArea } from '../CssGridArea';
import { PageContextProvider } from './PageContext';

interface PageContentWrapperProps {
  className?: string;
  children?: ReactNode | ReactNode[];
}

const GridArea = styled(CssGridArea)({
  display: 'flex',
  overflow: 'hidden',
});

export const PageContentWrapper = forwardRef<HTMLDivElement, PageContentWrapperProps>(
  (props, ref) => {
    return (
      <GridArea
        ref={ref}
        area="content"
        {...props}
        className={`page-content-wrapper ${props.className ? props.className : ''}`}
      >
        <PageContextProvider>{props.children}</PageContextProvider>
      </GridArea>
    );
  }
);
