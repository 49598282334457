import { createTheme } from '@mui/material/styles';
import { typography, components } from './common';

export const helixLight = createTheme({
  // @ts-ignore
  name: 'Light',
  palette: {
    mode: 'light',
    primary: {
      main: '#277EA1',
      light: '#60ADD2',
      dark: '#005272',
    },
    secondary: {
      main: '#CCDCE3',
    },
    error: {
      main: '#FF581B',
      light: '#FF8A4B',
      dark: '#BA2100',
    },
    warning: {
      main: '#EBA000',
      light: '#FFD148',
      dark: '#B37200',
    },
    info: {
      main: '#277EA1',
      light: '#60ADD2',
      dark: '#005272',
    },
    success: {
      main: '#23A48A',
      light: '#5FD5B8',
      dark: '#00735B',
    },
  },
  typography,
  components: {
    ...components,
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
      },
    },
  },
});
