import React from 'react';
import { Link, Paper, useTheme, Typography, Stack, Box, Divider } from '@mui/material';
import { SignantHealthLogo } from '../SignantHealthLogo';
import { COMPANY_NAME } from '../../constants';

export interface FooterProps {
  /**  Copyright year */
  copyrightYear?: string;
  /**  "Contact Us" URL */
  contactUsUrl?: string;
  /** flag to hide or show "Contact Us" link */
  hideContactUsLink?: boolean;
  /**  Terms of Service URL */
  supportUrl?: string;
  /** flag to hide or show "Terms of Service" link */
  hideSupportLink?: boolean;
  /**  Privacy Policy URL */
  privacyPolicyUrl?: string;
  /** flag to hide or show "Privacy Policy" link */
  hidePrivacyPolicyLink?: boolean;
  /**  flag to open the footer links in the same tab instead of the default
   * of using a new tab
   */
  openInSameTab?: boolean;
  /**
   * Name of application, displayed on right side before brand name
   */
  appName?: string;
  /**
   * Version of application. Should be formatted as SemVer, e.g. 1.0.0. Will only display if "appName" is also provided.
   */
  appVersion?: string;
}

const formatAppText = (appName: string = '', appVersion: string = '') => {
  const trimmedAppName = appName.trim();
  const trimmedAppVersion = appVersion.trim();

  if (!trimmedAppName) {
    return '';
  }

  if (appVersion) {
    return `${trimmedAppName} ${trimmedAppVersion}`;
  }

  return trimmedAppName;
};

export const Footer = (props: FooterProps) => {
  const {
    copyrightYear,
    contactUsUrl,
    supportUrl,
    privacyPolicyUrl,
    openInSameTab,
    appName,
    appVersion,
    hideContactUsLink,
    hideSupportLink,
    hidePrivacyPolicyLink,
  } = props;

  const theme = useTheme();

  const appText = formatAppText(appName, appVersion);

  const LinkStyle = {
    fontSize: '12px',
    mr: '11px',
    textDecoration: 'none',
  };

  return (
    <Paper elevation={6} square>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          textAlign: { xs: 'center', md: 'left' },
          justifyContent: { xs: 'center', md: 'space-between' },
          alignItems: 'center',
          paddingX: 2,
          minHeight: '40px',
          ...theme.typography.footer,
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }}>
          <Typography data-testid="copyright" variant="footer">
            Copyright &copy; {copyrightYear} {COMPANY_NAME}. All Rights Reserved.
          </Typography>
          <Box sx={{ ml: 2 }}>
            {!hideContactUsLink && (
              <Link
                target={openInSameTab ? '' : '_blank'}
                rel="noreferrer"
                href={contactUsUrl}
                sx={LinkStyle}
              >
                Contact Us
              </Link>
            )}
            {!hideSupportLink && (
              <Link
                target={openInSameTab ? '' : '_blank'}
                rel="noreferrer"
                href={supportUrl}
                sx={LinkStyle}
              >
                Support
              </Link>
            )}
            {!hidePrivacyPolicyLink && (
              <Link
                target={openInSameTab ? '' : '_blank'}
                rel="noreferrer"
                href={privacyPolicyUrl}
                sx={LinkStyle}
              >
                Privacy Policy
              </Link>
            )}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing="0.5rem"
          sx={{ alignItems: 'center', display: { xs: 'none', md: 'flex' } }}
        >
          {appText && (
            <>
              <Typography variant="footer" data-testid="app-name">
                {appText}
              </Typography>
              <Divider orientation="vertical" flexItem sx={{ ml: 1, mr: 1 }} />
            </>
          )}
          <Typography variant="footer">Powered by</Typography>
          <SignantHealthLogo />
        </Stack>
      </Stack>
    </Paper>
  );
};

Footer.defaultProps = {
  copyrightYear: new Date().getFullYear().toString(),
  contactUsUrl: 'https://www.signanthealth.com/support/contact-us',
  supportUrl: 'https://www.signanthealth.com/support',
  privacyPolicyUrl: 'https://www.signanthealth.com/privacy-policy',
  openInSameTab: false,
  hideContactUsLink: false,
  hideSupportLink: false,
  hidePrivacyPolicyLink: false,
};
