import React, { useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  Popover,
  styled,
  Toolbar,
  Typography,
  Avatar,
  Stack,
  Switch,
} from '@mui/material';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Dialog } from '../Dialog';
import logo from '../../assets/images/logo.svg';
import { COMPANY_NAME } from '../../constants';
import { AppSwitcherButton, AppSwitcherButtonProps } from '../AppSwitcherButton';
import { SecurityWrapperContext } from '../SecurityWrapper';
import { InactivityWarning } from '../SecurityWrapper/components/InactivityWarning';

export interface HeaderProps {
  /**  Header text URL. This is the target on clicking the application name. */
  homeUrl: string;
  /** Brand name. If provided, it will prepend the application name in the header. */
  brandName?: string;
  /** Trademark symbol. If provided, it will be appended to the brand name in the header. */
  trademarkSymbol?: string;
  /**
   * Application name. If provided, will show after the company logo in the header.
   * Image can also be passed as value.
   * @example
   * <Header appName={<img src="my/app/logo.png" />} />
   */
  appName?: string | React.ReactNode;
  /**  User initials. If provided, will show user avatar and drop-down menu on click. */
  userInitials?: string;
  /**  Props for AppSwitcher. If this prop is not defined, the AppSwitcher will not render. */
  appSwitcherProps?: AppSwitcherButtonProps;
  /** Optional list of app-specific navigation items to be displayed next to application name. */
  navigationItems?: React.ReactNode[];
  /**  Optional Function. If provided, toggles the theme mode. If not not provided, theme mode switch will be hidden */
  toggleTheme?: () => void;
  /**  Function to log out the user */
  logout?: () => void;
  /** Optional list of app-specific items to show at the top of the profile drop-down menu. */
  profileMenuItems?: React.ReactNode[];
  /** Optional parameter to disable <Popover> component display in a portal */
  disableMenuPortal?: boolean;
  /** Optional parameter to disable the <InactivityWarning> modal */
  disableInactivityWarning?: boolean;
  /** Text color for the header brand title. Defaults to 'inherit' */
  textColor?: string;
}

interface NavigationItemsProps {
  /** Optional list of app-specific navigation items to be displayed next to application name. */
  navigationItems?: React.ReactNode[];
}

export const NAVIGATION_ITEMS_ID = 'helx-navigation-items';

const NavigationItems: React.FC<NavigationItemsProps> = ({ navigationItems }) => {
  if (!navigationItems?.length) return null;
  return (
    <List sx={{ display: 'flex' }} data-testid={NAVIGATION_ITEMS_ID} id={NAVIGATION_ITEMS_ID}>
      <Divider orientation="vertical" flexItem sx={{ borderColor: 'rgba(255, 255, 255, 0.2)' }} />
      {navigationItems.map((navigationItem, index) => (
        <>
          <ListItem
            sx={{
              whiteSpace: 'nowrap',
              padding: '0.5em 1em',
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {navigationItem}
          </ListItem>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}
          />
        </>
      ))}
    </List>
  );
};

const HeaderLogo = styled('img')({
  maxHeight: '2rem',
  width: 'auto',
});

export const PROFILE_MENU_ID = 'helx-profile-menu';
export const PROFILE_TRIGGER_ID = 'helx-profile-menu-trigger';

export const Header = (props: HeaderProps) => {
  const {
    homeUrl,
    brandName,
    trademarkSymbol,
    appName,
    userInitials,
    appSwitcherProps,
    toggleTheme,
    logout,
    profileMenuItems,
    navigationItems,
    disableMenuPortal = false,
    disableInactivityWarning = false,
    textColor = 'inherit',
  } = props;

  const theme = useTheme();
  const isLightThemeActive = theme.palette.mode === 'light';

  const { triggerGlobalLogout } = useContext(SecurityWrapperContext);

  const [anchorProfileEl, setAnchorProfileEl] = useState<Element | null>(null);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  const isProfileMenuOpen = Boolean(anchorProfileEl);
  const isPopoverMouseOver = React.useRef(false);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorProfileEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorProfileEl(null);
  };

  const handleLogoutClick = () => {
    if (logout) {
      logout();
    }
    triggerGlobalLogout();
  };
  const handleMouseLeave = React.useCallback(() => {
    setTimeout(() => {
      if (!isPopoverMouseOver.current) {
        handleProfileMenuClose();
      }
    }, 50);
  }, []);

  const renderProfileMenu = (
    <Popover
      anchorEl={anchorProfileEl}
      disablePortal={disableMenuPortal}
      aria-labelledby={PROFILE_TRIGGER_ID}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      data-testid={PROFILE_MENU_ID}
      id={PROFILE_MENU_ID}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      PaperProps={{
        onMouseEnter: () => {
          isPopoverMouseOver.current = true;
        },
        onMouseLeave: () => {
          isPopoverMouseOver.current = false;
          handleProfileMenuClose();
        },
        sx: {
          maxWidth: '168px',
          zIndex: 1302,
        },
      }}
      sx={{
        position: 'inherit',
      }}
    >
      <List>
        {profileMenuItems && (
          <>
            {profileMenuItems.map((item, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index}>{item}</ListItem>
            ))}
            <Divider />
          </>
        )}
        {toggleTheme && (
          <>
            <ListItem>
              <Brightness7Icon fontSize="small" />
              <Switch
                checked={!isLightThemeActive}
                onChange={toggleTheme}
                inputProps={{ 'aria-label': 'toggle theme' }}
              />
              <DarkModeIcon fontSize="small" />
            </ListItem>

            <Divider />
          </>
        )}
        <ListItem disablePadding>
          <ListItemButton
            sx={{ color: 'error.main' }}
            onClick={() => setShowLogoutConfirmation(true)}
          >
            Log Out
          </ListItemButton>
        </ListItem>
      </List>
    </Popover>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <HeaderLogo src={logo} alt={COMPANY_NAME} role="presentation" width="120" height="120" />
          <Typography component="h1" variant="h6" sx={{ flexGrow: 1, display: 'flex' }} ml={2}>
            <Link
              href={homeUrl}
              color={textColor}
              underline="none"
              style={{ display: 'inline-flex' }}
            >
              {brandName && <span style={{ fontWeight: 'bold' }}>{brandName}</span>}
              {brandName && trademarkSymbol && (
                <span style={{ fontWeight: 'bold', fontSize: '16px', verticalAlign: 'top' }}>
                  {trademarkSymbol}
                </span>
              )}
              {brandName && ' '}
              {appName}
            </Link>
          </Typography>
          <NavigationItems navigationItems={navigationItems} />
          <Stack
            alignItems="center"
            alignSelf="right"
            direction="row"
            spacing={0}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}
              />
            }
          >
            {userInitials && appSwitcherProps && <AppSwitcherButton {...appSwitcherProps} />}
            {userInitials && (
              <IconButton
                aria-label="account of current user"
                aria-controls={isProfileMenuOpen ? PROFILE_MENU_ID : undefined}
                aria-haspopup="true"
                aria-expanded={isProfileMenuOpen ? 'true' : undefined}
                data-testid={PROFILE_TRIGGER_ID}
                id={PROFILE_TRIGGER_ID}
                onMouseEnter={handleProfileMenuOpen}
                onMouseLeave={handleMouseLeave}
                onClick={handleProfileMenuOpen}
                sx={{ minWidth: 0, padding: '0 0.67em' }}
              >
                <Avatar sx={{ bgcolor: 'white', color: 'black' }}>{userInitials}</Avatar>
              </IconButton>
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {renderProfileMenu}
      {!disableInactivityWarning && <InactivityWarning />}
      <Dialog
        data-testid="confirm-logout-modal"
        open={showLogoutConfirmation}
        handleClose={() => setShowLogoutConfirmation(false)}
        title="Confirm Log Out"
        content={
          <Typography paragraph>
            Are you sure you want to log out? Unsaved changes in all open applications will be lost.
          </Typography>
        }
        actionButtonProps={[
          {
            key: 'cancel',
            variant: 'outlined',
            color: 'primary',
            size: 'large',
            onClick: () => setShowLogoutConfirmation(false),
            children: 'Stay Logged In',
          },
          {
            key: 'confirm',
            variant: 'contained',
            color: 'primary',
            size: 'large',
            onClick: handleLogoutClick,
            children: 'Log Out',
          },
        ]}
        dialogContentSx={{
          maxWidth: '300px',
        }}
      />
    </>
  );
};

Header.displayName = 'Header';
