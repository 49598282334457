import React, { useEffect, useRef } from 'react';
import AppsIcon from '@mui/icons-material/DynamicFeed';
import { IconButton, useTheme } from '@mui/material';
import AppSwitcher from 'app-switcher';

export const APPS_MENU_ID = 'app-switcher';
export const APPS_TRIGGER_ID = 'helx-apps-menu-trigger';

export interface AppSwitcherButtonProps {
  idPortalUrl: string;
  idPortalApiUrl: string;
  accessToken: string;
  clientApp: string;
  onAppNavigation?: (appNavigation: { appUrl: string; navigate: () => void }) => void;
}

export const AppSwitcherButton = ({
  idPortalUrl,
  idPortalApiUrl,
  accessToken,
  clientApp,
  onAppNavigation,
}: AppSwitcherButtonProps) => {
  const theme = useTheme();

  const appSwitcherRef = useRef<AppSwitcher>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const isPopoverMouseOver = React.useRef(false);

  const openAppSwitcher = () => {
    appSwitcherRef.current?.setOpen(true);
  };

  const closeAppSwitcher = () => {
    setTimeout(() => {
      if (!isPopoverMouseOver.current) {
        appSwitcherRef.current?.setOpen(false);
      }
    }, 100);
  };
  // Setup AppSwitcher on first render, and recreate if ID Portal URL(s) change
  useEffect(() => {
    if (buttonRef.current) {
      appSwitcherRef.current = new AppSwitcher({
        anchorEl: `#${buttonRef.current.id}`,
        ID_PORTAL_URL: idPortalUrl,
        ID_PORTAL_API_URL: idPortalApiUrl,
        accessToken,
        clientApp,
        mode: theme.palette.mode,
        popoverProps: {
          id: 'app-switcher',
          style: {
            // override the AppSwitcher popover z-index to match the Header
            zIndex: 1100,
          },
          onMouseLeave: () => {
            isPopoverMouseOver.current = false;
            closeAppSwitcher();
          },
          onMouseEnter: () => {
            isPopoverMouseOver.current = true;
          },
        },
        onAppNavigation,
      });
    }

    return () => {
      appSwitcherRef.current?.unmount();
    };
  }, [idPortalUrl, idPortalApiUrl]);

  // Update AppSwitcher mode on theme change
  useEffect(() => {
    if (appSwitcherRef.current) {
      appSwitcherRef.current.setMode(theme.palette.mode);
    }
  }, [theme.palette.mode]);

  // Update AppSwitcher accessToken if it changes
  useEffect(() => {
    if (appSwitcherRef.current) {
      appSwitcherRef.current.setAccessToken(accessToken);
    }
  }, [accessToken]);

  return (
    <IconButton
      style={{ padding: '0 0.67em' }}
      ref={buttonRef}
      aria-label="applications menu"
      aria-controls={APPS_MENU_ID}
      aria-haspopup="true"
      color="inherit"
      data-testid={APPS_TRIGGER_ID}
      id={APPS_TRIGGER_ID}
      onClick={openAppSwitcher}
      onMouseEnter={openAppSwitcher}
      onMouseLeave={closeAppSwitcher}
    >
      <AppsIcon fontSize="small" />
    </IconButton>
  );
};
