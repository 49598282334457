import React from 'react';
import { Icon, IconProps } from '@mui/material';

const getSizeAttributes = (size: IconProps['fontSize']) => {
  switch (size) {
    case 'large':
      return {
        padding: '2.5px',
        fontSize: '1.875rem',
      };
    case 'small':
      return {
        padding: '1px',
        fontSize: '1.125rem',
      };
    default:
      return {
        padding: '3px',
        fontSize: '1.25rem',
      };
  }
};

export interface FAIconProps {
  icon: string;
  family?: 'solid' | 'regular';
  color?: IconProps['color'];
  fontSize?: IconProps['fontSize'];
  sx?: IconProps['sx'];
  'data-testid'?: string;
}

export const FontAwesomeIcon = (props: FAIconProps) => {
  const { icon, family, color, fontSize, sx, 'data-testid': dataTestId } = props;

  const sxWithDefaults: IconProps['sx'] = {
    boxSizing: 'content-box',
    ...getSizeAttributes(fontSize),
    ...sx,
  };

  return (
    <Icon
      baseClassName={`fa-${family}`}
      className={`fa-${icon}`}
      color={color}
      sx={sxWithDefaults}
      data-testid={dataTestId}
    />
  );
};

const defaultProps: Partial<FAIconProps> = {
  family: 'solid',
  fontSize: 'inherit',
};

FontAwesomeIcon.defaultProps = defaultProps;
