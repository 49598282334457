import { createTheme } from '@mui/material/styles';
import { typography, components } from './common';

export const helixDark = createTheme({
  // @ts-ignore
  name: 'Dark',
  palette: {
    mode: 'dark',
    primary: {
      main: '#44B6E6',
      light: '#80E8FF',
      dark: '#0086B4',
    },
    secondary: {
      main: '#1D4A66',
    },
    error: {
      main: '#FF581B',
      light: '#FF8A4B',
      dark: '#C41F00',
    },
    warning: {
      main: '#FFCA65',
      light: '#FFFD95',
      dark: '#C99936',
    },
    info: {
      main: '#44B6E6',
      light: '#80E8FF',
      dark: '#0086B4',
    },
    success: {
      main: '#4FCEA7',
      light: '#86FFD8',
      dark: '#009C78',
    },
    background: {
      default: '#243B53',
      paper: '#102A43',
    },
  },
  typography,
  components,
});
