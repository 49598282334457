export interface CookieOptions {
  domain?: string;
  path?: string;
  maxAge?: number;
  secure?: boolean;
  sameSite?: 'Lax' | 'Strict' | 'None';
}

const read = (name: string): string | undefined => {
  const cookie = document.cookie.match(`(^|[^;]+)\\s*${name}\\s*=\\s*([^;]+)`);

  return cookie ? cookie.pop() : '';
};

const write = (name: string, value: string, options: CookieOptions) => {
  const segments = [`${name}=${value}`];

  if (options.domain) {
    segments.push(`domain=${options.domain}`);
  }

  if (options.path) {
    segments.push(`path=${options.path}`);
  }

  if (typeof options.maxAge === 'number') {
    segments.push(`max-age=${options.maxAge}`);
  }

  if (options.secure) {
    segments.push('Secure');
  }

  if (options.sameSite) {
    segments.push(`SameSite=${options.sameSite}`);
  }

  document.cookie = segments.join('; ');
};

const remove = (name: string, options: Omit<CookieOptions, 'maxAge'>) => {
  write(name, '', { ...options, maxAge: 0 });
};

export const cookieUtils = {
  read,
  write,
  remove,
};
