import { CookieOptions, cookieUtils } from './cookieUtils';

interface GlobalSessionCookie {
  user: string;
  lastActivityTimestamp: number;
}

export interface GlobalLogoutManagerConfig {
  /**
   * Callback that will be invoked when the GlobalLogoutManager detects that a logout must happen.
   * For example, when another app has triggered a global logout
   */
  onGlobalLogout: () => void;
  /**
   * User email address. Will be used to detect changes in Okta ID Token
   */
  userEmail: string;
  /**
   * Callback that will be invoked when a change is detected in the user email address
   */
  onUserChange: () => void;
  /**
   * Callback that will be invoked when the inactivity timeout warning period begins. (timeout is 15 minutes, warning is 2 minutes prior)
   * @param logoutTimestamp
   */
  onTimeoutWarning: (logoutTimestamp: number) => void;
  /**
   * Callback that will be invoked during the timeout warning period if another app has recorded a new user activity timestamp
   */
  onExternalActivityDetected: () => void;
  /**
   * Interval in which GlobalLogoutManager will poll for global logout and inactivity detection.
   *
   * Defaults to 5000 ms (5 seconds)
   */
  pollIntervalMs?: number;
}

const getRootDomain = (): string => {
  return window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
};

const DEFAULT_ACTIVITY_TIMEOUT_MS = 15 * 60 * 1000; // 15 minutes
const DEFAULT_TIMEOUT_WARNING_PERIOD_MS = 2 * 60 * 1000; // 2 minutes
const DEFAULT_POLL_INTERVAL_MS = 5000;
const ACTIVITY_THROTTLE_INTERVAL_MS = 2000;

const COOKIE_NAME = 'SH-Session';

const COOKIE_OPTIONS: Readonly<CookieOptions> = {
  path: '/',
  domain: getRootDomain(),
  maxAge: 60 * 60 * 24 * 400, // 400 days in seconds
  secure: true,
  sameSite: 'Lax',
};

let intervalId: ReturnType<typeof setInterval> | null = null;

let managerConfig: GlobalLogoutManagerConfig | null = null;

let setupFlag = false;

let warningTimestamp: number | null = null;

let mediaObserver: MutationObserver;
let mediaElements = document.querySelectorAll('audio, video');
let canLookForMediaElements = true;

const throttle = (callback, interval) => {
  let enableCall = true;

  return (...args) => {
    if (!enableCall) return;

    enableCall = false;
    callback.apply(this, args);
    setTimeout(() => {
      enableCall = true;
    }, interval);
  };
};

const readCookie = (): GlobalSessionCookie | null => {
  const cookie = cookieUtils.read(COOKIE_NAME);

  try {
    return cookie ? (JSON.parse(cookie) as GlobalSessionCookie) : null;
  } catch {
    return null;
  }
};

const writeCookie = (cookie: Partial<GlobalSessionCookie>) => {
  const previousCookieValue = readCookie();
  const newCookieValue = { ...previousCookieValue, ...cookie };

  const valueString = JSON.stringify(newCookieValue);

  cookieUtils.write(COOKIE_NAME, valueString, COOKIE_OPTIONS);
};

const deleteCookie = () => {
  cookieUtils.remove(COOKIE_NAME, {
    domain: COOKIE_OPTIONS.domain,
    path: COOKIE_OPTIONS.path,
    secure: COOKIE_OPTIONS.secure,
    sameSite: COOKIE_OPTIONS.sameSite,
  });
};

const onUserAction = throttle(() => {
  /** Timestamp should only update if cookie exists.
   * If cookie does not exist, another app has triggered global logout.
   * Without this check, User activity occurring between another app's global logout and this app's polling period
   * will reset the cookie and short-circuit the polling, preventing this app from performing its logout behavior.
   * More details in Jira: STMA-322
   */
  if (readCookie()) {
    writeCookie({ lastActivityTimestamp: Date.now() });
  }
}, ACTIVITY_THROTTLE_INTERVAL_MS);

const removeMediaEventListeners = () => {
  if (mediaElements.length !== 0) {
    mediaElements.forEach((media) => {
      media.removeEventListener('timeupdate', onUserAction);
    });
  }
};

const setupMediaEventListeners = () => {
  mediaElements = document.querySelectorAll('audio, video');

  if (mediaElements.length > 0) {
    mediaElements.forEach((media) => {
      media.addEventListener('timeupdate', onUserAction);
    });
  }
};

const updateMediaElements = () => {
  if (canLookForMediaElements) {
    canLookForMediaElements = false;

    removeMediaEventListeners();
    setupMediaEventListeners();

    setTimeout(() => {
      canLookForMediaElements = true;
    }, ACTIVITY_THROTTLE_INTERVAL_MS);
  }
};

const setupMediaObserver = () => {
  // Create an observer to look for new videos when the DOM mutates.
  mediaObserver = new MutationObserver(updateMediaElements);

  const targetNode = document.body;

  const config = { childList: true, subtree: true };

  mediaObserver.observe(targetNode, config);
};

const startActivityTracker = (): void => {
  // set up event listeners for user activity
  window.addEventListener('mousemove', onUserAction);
  window.addEventListener('keydown', onUserAction);
  window.addEventListener('play', onUserAction);
  window.addEventListener('pause', onUserAction);
  window.addEventListener('touchstart', onUserAction);

  setupMediaObserver();
};

const stopActivityTracker = () => {
  // clean up event listeners
  window.removeEventListener('mousemove', onUserAction);
  window.removeEventListener('keydown', onUserAction);
  window.removeEventListener('play', onUserAction);
  window.removeEventListener('pause', onUserAction);
  window.removeEventListener('touchstart', onUserAction);

  removeMediaEventListeners();
  if (mediaObserver) mediaObserver.disconnect();
};

const setupError = () => {
  return new Error('GlobalLogoutManger has not been initialized.');
};

const setup = (config: GlobalLogoutManagerConfig) => {
  if (setupFlag) {
    throw new Error('GlobalLogoutManager has already been initialized.');
  }

  managerConfig = config;

  setupFlag = true;
};

const triggerGlobalLogout = (): void => {
  if (!setupFlag) {
    throw setupError();
  }

  deleteCookie();
};

const createCookie = (): void => {
  if (!setupFlag) {
    throw setupError();
  }

  writeCookie({
    user: managerConfig?.userEmail ?? '',
    lastActivityTimestamp: Date.now(),
  });
};

const stopPolling = (): void => {
  // clean up interval
  stopActivityTracker();
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

const intervalFunction = () => {
  if (!managerConfig) {
    stopPolling();
    throw new Error('GlobalLogoutManager was reset while actively polling.');
  }

  const cookie = readCookie();

  if (!cookie) {
    // perform global logout
    stopPolling();
    managerConfig.onGlobalLogout();
    return;
  }

  // detect if the user has been changed by another app
  if (cookie?.user && managerConfig.userEmail !== cookie.user) {
    managerConfig.onUserChange();
    return;
  }

  const { lastActivityTimestamp } = cookie;
  const currentTime = Date.now();
  const isPastWarningPeriod =
    lastActivityTimestamp <
    currentTime - (DEFAULT_ACTIVITY_TIMEOUT_MS - DEFAULT_TIMEOUT_WARNING_PERIOD_MS);
  const isPastTimeoutPeriod = lastActivityTimestamp < currentTime - DEFAULT_ACTIVITY_TIMEOUT_MS;

  // detect if warning period should be triggered
  if (warningTimestamp === null && isPastWarningPeriod) {
    stopActivityTracker();
    warningTimestamp = currentTime;
    managerConfig.onTimeoutWarning(lastActivityTimestamp + DEFAULT_ACTIVITY_TIMEOUT_MS);
    return;
  }

  // detect if another app has updated last activity within this app's warning period
  if (warningTimestamp && lastActivityTimestamp > warningTimestamp) {
    warningTimestamp = null;
    writeCookie({ lastActivityTimestamp: Date.now() });
    startActivityTracker();
    managerConfig.onExternalActivityDetected();
    return;
  }

  // detect if full timeout period has elapsed with no activity
  if (warningTimestamp && isPastTimeoutPeriod) {
    stopPolling();
    managerConfig.onGlobalLogout();
  }
};

const dismissTimeoutWarning = () => {
  writeCookie({ lastActivityTimestamp: Date.now() });
  warningTimestamp = null;
  startActivityTracker();
};

const startPolling = (): void => {
  if (!setupFlag) {
    console.warn('GlobalLogoutManager.startPolling was called prior to manager setup.');
    return;
  }

  // If interval is already running, exit early to avoid creating another interval
  if (intervalId !== null) {
    return;
  }

  // run interval function before first delay
  intervalFunction();

  const pollInterval = managerConfig?.pollIntervalMs ?? DEFAULT_POLL_INTERVAL_MS;
  startActivityTracker();
  intervalId = setInterval(intervalFunction, pollInterval);
};

const reset = () => {
  stopPolling();
  managerConfig = null;
  setupFlag = false;
};

const isSetup = () => {
  return setupFlag;
};

export default {
  setup,
  reset,
  isSetup,
  triggerGlobalLogout,
  createCookie,
  startPolling,
  stopPolling,
  dismissTimeoutWarning,
};
