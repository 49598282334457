import React, { forwardRef, ReactNode } from 'react';
import { styled, Box } from '@mui/material';

export interface CssGridProps {
  gridTemplateRows?: string;
  gridTemplateColumns?: string;
  gridTemplateAreas: string;
  height?: string;
  width?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
}

const StyledGrid = styled(Box)<CssGridProps>(
  ({ gridTemplateRows, gridTemplateColumns, gridTemplateAreas, height, width }) => ({
    display: 'grid',
    height: height || '100vh',
    width: width || '100%',
    gridTemplateRows,
    gridTemplateColumns,
    gridTemplateAreas,
  })
);

export const CssGrid = forwardRef<HTMLDivElement, CssGridProps>((props, ref) => {
  return (
    <StyledGrid
      ref={ref}
      {...props}
      className={`grid-css ${props.className ? props.className : ''}`}
    >
      {props.children}
    </StyledGrid>
  );
});
