import React from 'react';
import './styleGuide.scss';

const ColorsLight = () => {
  const colors: Array<[string, string, string[]]> = [
    ['Minty Greens', 'green', ['primary', 'light', 'dark']],
    ['Signant Blues', 'blue', ['primary', 'light', 'dark']],
    ['Signant Secondary Blues', 'sec-blue', ['light', 'dark']],
    ['Golden Gates', 'golden', ['primary', 'light', 'dark']],
    ['Signant Yellows', 'yellow', ['primary', 'light', 'dark']],
    ['Bright Oranges', 'orange', ['primary', 'light', 'dark']],
    ['Ashe Monotones', 'ashe-black', ['primary', 'light', 'dark']],
    ['Ashe Monotones', 'ashe-white', ['primary', 'light', 'dark']],
  ];

  return (
    <article className="styleguide-colors">
      <h2>Colors Light</h2>
      {colors.map(([name, className, variants]) => {
        return (
          <div key={className} id={`var-${name}`} className="styleguide-color">
            <label htmlFor={`var-${name}`}>{name}</label>
            {variants.map((variant) => (
              <div key={variant}>
                <code>{`--light-theme-${className}-${variant}`}</code>
                <span
                  className="styleguide-color-value"
                  style={
                    {
                      backgroundColor: `var(--light-theme-${className}-${variant})`,
                    } as React.CSSProperties
                  }
                />
              </div>
            ))}
          </div>
        );
      })}
    </article>
  );
};

const ColorsDark = () => {
  const colors: Array<[string, string, string[]]> = [
    ['Minty Greens', 'green', ['primary', 'light', 'dark']],
    ['Signant Blues', 'blue', ['primary', 'light', 'dark']],
    ['Signant Secondary Blues', 'sec-blue', ['light', 'dark']],
    ['Golden Gates', 'golden', ['primary', 'light', 'dark']],
    ['Signant Yellows', 'yellow', ['primary', 'light', 'dark']],
    ['Bright Oranges', 'orange', ['primary', 'light', 'dark']],
    ['Ashe Monotones', 'ashe-black', ['primary', 'light', 'dark']],
    ['Ashe Monotones', 'ashe-white', ['primary', 'light', 'dark']],
  ];

  return (
    <article className="styleguide-colors" style={{ backgroundColor: '#243B53', color: 'white' }}>
      <h2>Colors Dark</h2>
      {colors.map(([name, className, variants]) => {
        return (
          <div key={className} className="styleguide-color">
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            <label>{name}</label>
            {variants.map((variant) => (
              <div key={variant}>
                <code>{`--dark-theme-${className}-${variant}`}</code>
                <span
                  className="styleguide-color-value"
                  style={
                    {
                      backgroundColor: `var(--dark-theme-${className}-${variant})`,
                    } as React.CSSProperties
                  }
                />
              </div>
            ))}
          </div>
        );
      })}
    </article>
  );
};

export interface StyleGuideProps {
  colorsLight?: boolean;
  colorsDark?: boolean;
}
export const StyleGuide = (props: StyleGuideProps): JSX.Element => {
  const { colorsLight, colorsDark } = props;

  return (
    <section className="styleguide-wrapper">
      <h1>
        These are the assets of our StyleGuide. Please take them into consideration when building
        new components or UI&apos;s.
      </h1>

      {colorsLight && <ColorsLight />}
      {colorsDark && <ColorsDark />}
    </section>
  );
};
