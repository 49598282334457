import * as React from 'react';
import Alert, { AlertProps } from '@mui/material/Alert';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';

import { styled } from '@mui/material';

export interface NotificationProps {
  /** flag indicating if the offset should be adjusted so the notification isn't
  on top of the footer (assumes FelFooter is used) */
  adjustForFooter?: boolean;
  /** props for the snackbar */
  snackbarProps?: SnackbarProps;
  /** props for the alert embedded in the snackbar */
  alertProps?: AlertProps;
  /** message to display */
  children: React.ReactNode;
}

/**
 * Reusable header component.
 * @type {object}
 * @description Displays a notification component (snackbar).  Default position is bottom center of the screen but can
 * be changed with the snackbarProps.  If adjustForFooter is set to true it will be adjusted upward so that it is above
 * the footer (assumes the FelFooter is used)
 * @param props
 */

const CustomizedAlert = styled(Alert)(() => ({
  '.fel-notification': {
    '&--offset': {
      position: 'relative',
      bottom: '60px',
    },
  },
}));

export const Notification = (props: NotificationProps) => {
  const { adjustForFooter = false, snackbarProps, alertProps, children } = props;

  const defaultSnackbarProps = {
    autoHideDuration: 5000,
  };
  const defaultAlertProps = {
    elevation: 6,
  };

  return (
    <Snackbar {...defaultSnackbarProps} {...snackbarProps}>
      <CustomizedAlert
        variant="filled"
        severity="error"
        classes={{ root: adjustForFooter ? 'fel-notification--offset' : '' }}
        {...defaultAlertProps}
        {...alertProps}
      >
        {children}
      </CustomizedAlert>
    </Snackbar>
  );
};
