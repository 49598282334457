import React, { useContext, useEffect, useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { Dialog } from '../../Dialog';
import { SecurityWrapperContext } from '../SecurityWrapper';

const formatTimeRemaining = (logoutTimestamp: number) => {
  let currentTimestamp = logoutTimestamp - Date.now();

  // Clamp the value to a minimum of zero to prevent overflow from showing anything after 00:00
  if (currentTimestamp < 0) {
    currentTimestamp = 0;
  }

  const timeRemainingMs = new Date(currentTimestamp);

  const minutes = timeRemainingMs.getUTCMinutes().toString().padStart(2, '0');
  const seconds = timeRemainingMs.getUTCSeconds().toString().padStart(2, '0');

  return `${minutes}:${seconds}`;
};

export const InactivityWarning = () => {
  const { showInactivityWarning, handleStayLoggedIn, triggerGlobalLogout, logoutTimestamp } =
    useContext(SecurityWrapperContext);

  const [timeRemaining, setTimeRemaining] = useState('');
  const previousTitleRef = useRef('');

  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (logoutTimestamp !== null) {
      previousTitleRef.current = document.title;

      intervalId.current = setInterval(() => {
        const formattedTime = formatTimeRemaining(logoutTimestamp);
        setTimeRemaining(formatTimeRemaining(logoutTimestamp));
        document.title = `Session expires in ${formattedTime} - ${previousTitleRef.current}`;
      }, 1000);
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
        document.title = previousTitleRef.current;
        previousTitleRef.current = '';
        intervalId.current = null;
      }
    };
  }, [logoutTimestamp]);

  return (
    <Dialog
      data-testid="activity-timeout-modal"
      open={showInactivityWarning}
      title="Your session is about to expire"
      content={
        <Typography paragraph>
          You will be automatically logged out in {timeRemaining} due to inactivity. Select{' '}
          <strong>Stay Logged In</strong> to keep working or <strong>Log Out</strong> to end your
          session now.
        </Typography>
      }
      actionButtonProps={[
        {
          key: 'logout',
          variant: 'outlined',
          color: 'primary',
          size: 'large',
          onClick: triggerGlobalLogout,
          children: 'Log Out',
        },
        {
          key: 'stay-logged-in',
          variant: 'contained',
          color: 'primary',
          size: 'large',
          onClick: handleStayLoggedIn,
          children: 'Stay Logged In',
        },
      ]}
      dialogContentSx={{
        maxWidth: '300px',
      }}
    />
  );
};
