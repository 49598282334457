let isVisible = true;

type BrowserPrefixType = 'moz' | 'ms' | 'o' | 'webkit' | '';
type HiddenPropertyNameType = `${BrowserPrefixType}Hidden` | 'hidden';
type VisibilityEventNameType = `${BrowserPrefixType}visibilitychange`;

const browserPrefixes: BrowserPrefixType[] = ['moz', 'ms', 'o', 'webkit'];

export const getHiddenPropertyName = (prefix: BrowserPrefixType): HiddenPropertyNameType =>
  prefix ? `${prefix}Hidden` : 'hidden';
export const getVisibilityEvent = (prefix: BrowserPrefixType): VisibilityEventNameType =>
  `${prefix}visibilitychange`;
export const getBrowserPrefix = (prefixes: BrowserPrefixType[]): BrowserPrefixType =>
  prefixes.find((prefix: BrowserPrefixType) => getHiddenPropertyName(prefix) in document) ??
  ('' as BrowserPrefixType);

const browserPrefix: BrowserPrefixType = getBrowserPrefix(browserPrefixes);
const hiddenPropertyName: HiddenPropertyNameType = getHiddenPropertyName(browserPrefix);
const visibilityEventName: VisibilityEventNameType = getVisibilityEvent(browserPrefix);

const onVisible = (callback = () => {}) => {
  if (isVisible) return;
  isVisible = true;
  callback();
};

const onHidden = (callback = () => {}) => {
  if (!isVisible) return;
  isVisible = false;
  callback();
};

let visibleCallback: () => void;
let hiddenCallback: () => void;

const onVisibilityChange = () =>
  // @ts-ignore
  document[hiddenPropertyName] ? onHidden(hiddenCallback) : onVisible(visibleCallback);

const onPageFocus = () => onVisible(visibleCallback);
const onPageBlur = () => onHidden(hiddenCallback);

const unsubscribe = () => {
  document.removeEventListener(visibilityEventName, onVisibilityChange);
  document.removeEventListener('focus', onPageFocus);
  window.removeEventListener('focus', onPageFocus);
  document.removeEventListener('blur', onPageBlur);
  window.removeEventListener('blur', onPageBlur);
};

const subscribe = (onVisibleFn: () => void = () => {}, onHidenFn: () => void = () => {}): void => {
  unsubscribe();

  visibleCallback = onVisibleFn;
  hiddenCallback = onHidenFn;

  document.addEventListener(visibilityEventName, onVisibilityChange, false);
  document.addEventListener('focus', onPageFocus, false);
  window.addEventListener('focus', onPageFocus, false);
  document.addEventListener('blur', onPageBlur, false);
  window.addEventListener('blur', onPageBlur, false);
};

export default { subscribe, unsubscribe };
