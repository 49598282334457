export const defaultApiHeaders: HeadersInit = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  pragma: 'no-cache',
  'cache-control': 'no-cache',
};

export const HTTP_RESPONSE_MESSAGES = {
  NO_RECORDS_FOUND: 'No records found',
  OFFLINE_ERROR: 'There is no internet connection. Please check your internet connection.',
  API_FAILURE_ERROR: 'Error retrieving data.',
  API_GET_METHOD_FAIL_ERROR: 'Failed Retrieving Data',
  API_POST_METHOD_FAIL_ERROR: 'Save Failed',
};
