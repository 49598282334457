import React, { forwardRef, ReactNode } from 'react';
import { styled } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import classNames from 'classnames';
import { usePageContext } from './PageContext';

interface PageMainWrapperProps {
  sidebarWidth?: string;
  className?: string;
  children?: ReactNode | ReactNode[];
  sx?: SxProps;
}

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'sidebarWidth',
})<{
  open?: boolean;
  sidebarWidth?: string;
}>(({ theme, open, sidebarWidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  overflow: 'auto',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${sidebarWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const PageMainWrapper = forwardRef<HTMLDivElement, PageMainWrapperProps>(
  ({ sidebarWidth, className, ...props }, ref) => {
    const { open } = usePageContext();
    return (
      <Main
        open={open}
        sidebarWidth={sidebarWidth}
        className={classNames('page-main-wrapper', className)}
        {...props}
        ref={ref}
      />
    );
  }
);
