import * as React from 'react';
import { styled, Typography, Box, CircularProgress } from '@mui/material';

const ProgressBackground = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? 'black' : '#707070',
  opacity: '50%',
  pointerEvents: 'none',
  zIndex: 2000,
}));

const ProgressContainer = styled(Box)({
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2000,
});

const ProgressBox = styled(Box)(({ theme }) => ({
  maxWidth: '300px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '15px',
  padding: '15px 25px',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'var(--dark-theme-ashe-black-primary)'
      : 'var(--light-theme-ashe-white-light)',
  borderRadius: '6px',
  zIndex: 2000,
}));

export interface ProgressOverlayProps {
  /**  id for overlay div */
  id?: string;
  /**  id for spinner */
  spinnerId?: string;
  /**  flag to determine if spinner is displayed */
  inProgress: boolean;
  /**  size of spinner (default is 40) */
  spinnerSize?: number;
  /**  message to display under the spinner */
  message?: string;
  /** flag to determine whether the overlay covers the whole page, or just its parent div */
  fullOverlay?: boolean;
}

/**
 * Reusable progress spinner component.
 * @type {object}
 * @description Displays a overlay div with a spinner when the inProgress flag is set to true
 * @param props
 */
export const ProgressOverlay = (props: ProgressOverlayProps) => {
  const {
    id = 'progressDiv',
    spinnerId = 'progressSpinner',
    inProgress = true,
    spinnerSize = 40,
    message,
    fullOverlay = true,
  } = props;

  const fixedStyle = { position: 'fixed' };
  const relativeStyle = { position: 'relative' };
  const absoluteStyle = { position: 'absolute' };

  if (!inProgress) return null;

  return (
    <ProgressContainer sx={fullOverlay ? fixedStyle : relativeStyle}>
      <ProgressBackground
        id={id}
        data-testid="progressDivTest"
        sx={fullOverlay ? fixedStyle : absoluteStyle}
      />
      <ProgressBox sx={fullOverlay ? fixedStyle : {}}>
        <CircularProgress id={spinnerId} size={spinnerSize} data-testid="progressSpinnerIcon" />
        {message && <Typography data-testid="progressMessage">{message}</Typography>}
      </ProgressBox>
    </ProgressContainer>
  );
};
