import React from 'react';
import { Components, Theme } from '@mui/material';
import { FontAwesomeIcon } from '../components';
import type {} from '@mui/x-date-pickers/themeAugmentation';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    footer: React.CSSProperties;
    xxl: React.CSSProperties;
    xl: React.CSSProperties;
    large: React.CSSProperties;
    xm: React.CSSProperties;
    medium: React.CSSProperties;
    base: React.CSSProperties;
    small: React.CSSProperties;
    xs: React.CSSProperties;
    xxs: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    footer?: React.CSSProperties;
    xxl?: React.CSSProperties;
    xl?: React.CSSProperties;
    large?: React.CSSProperties;
    xm?: React.CSSProperties;
    medium?: React.CSSProperties;
    base?: React.CSSProperties;
    small?: React.CSSProperties;
    xs?: React.CSSProperties;
    xxs?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    footer: true;
    xxl: true;
    xl: true;
    large: true;
    xm: true;
    medium: true;
    base: true;
    small: true;
    xs: true;
    xxs: true;
  }
}

export const typography = {
  fontFamily: '"Noto Sans", sans-serif',
  // Add a new "footer" variant for Typography
  footer: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },

  // Styleguide Variants
  xxl: {
    fontSize: '2rem',
  },
  xl: {
    fontSize: '1.75rem',
  },
  large: {
    fontSize: '1.5rem',
  },
  xm: {
    fontSize: '1.25rem',
  },
  medium: {
    fontSize: '1.125rem',
  },
  base: {
    fontSize: '1rem',
  },
  small: {
    fontSize: '1rem',
  },
  xs: {
    fontSize: '0.75rem',
  },
  xxs: {
    fontSize: '0.625rem',
  },
};

export const absCenterStyles = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

export const textEllipsisStyles = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const components: Components<Omit<Theme, 'components'>> = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        '& legend': {
          color: 'inherit',
        },
        '& legend.Mui-focused': {
          color: 'inherit',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 6,
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
      }),
    },
  },
  MuiDatePicker: {
    defaultProps: {
      slots: {
        openPickerIcon: () => <FontAwesomeIcon icon="calendar" family="regular" />,
        rightArrowIcon: () => <FontAwesomeIcon icon="angle-right" />,
        leftArrowIcon: () => <FontAwesomeIcon icon="angle-left" />,
      },
    },
  },
};
